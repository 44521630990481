<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Lista de instalações</h1>
      <div class="box-table">
        <v-skeleton-loader
            :loading="loadingTable"
            :transition="'fade-transition'"
            height="500"
            type="table">
          <v-data-table
            :headers="headers"
            :items="listaItens"
            :search="search"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"
            @click:row="editItem"> 

            <template v-slot:top>
              <BrToolBar
                @search="buscar"
                :configFilter="{
                    listaSelect: listaKeys,
                    getItens,
                    jsonData: false,
                    switchStatus,
                    activeSwitchStatus: true,
                    isStatus,
                    switchStatusName: 'Incluir Desativados'
                  }"
                :addlFilter="true"
                :labelTextField="'Busca por código do device:'">
                
                <template v-slot:btnSelect>
                  <v-col cols="12" md="2">
                    <v-select
                      :items="listaItensSelect"
                      v-model="typeBusca"
                      label="tipo Busca"
                      dense
                      solo
                    ></v-select>
                  </v-col>
                </template>
              </BrToolBar>
            </template>

             <template v-slot:item.date="{ item }">
              {{ item.date }}
            </template>

             <template v-slot:item.delete="{ item }">
              {{ item.delete === '01/01/1970 00:00:00' ? '-' : item.delete }}
            </template>

             <template v-slot:item.status="{ item }">
              <v-chip :color="getColor(item.delete)" dark>{{ status(item.delete) }}</v-chip>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="10"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import Events from '@/core/service/events'
import moment from 'moment'
import { errorSnackbar  } from '@/core/service/utils'
import { forEach } from 'lodash'

/*
* Mixins
*/
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'UsuarioWeb',
  mixins: [listagemTables],
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  data: () => ({
    search: '',
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Entidades', value: 'entity.name' },
      { align: 'start', class: 'table-header', text: 'Código da entidade', value: 'entity.cod' },
      { align: 'start', class: 'table-header', text: 'ICCID', value: 'chip.iccid' },
      { align: 'start', class: 'table-header', text: 'Devices', value: 'device.serial' },
      { align: 'start', class: 'table-header', text: 'Versão do Device', value: 'device.version' },
      { align: 'start', class: 'table-header', text: 'Código Devices', value: 'device.hwSerial' },
      { align: 'start', class: 'table-header', text: 'Criado', value: 'date' },
      { align: 'start', class: 'table-header', text: 'Deletado', value: 'delete' },
      { align: 'end', class: 'table-header', text: 'Status', value: 'status' },
    ],
    typeBusca: null
  }),
  computed: {
    ...mapGetters('instalacoes', {
      listaItens: 'listaItens',
      totalItens: 'totalItens',
      isStatus: 'isStatus'
    }),
    ...mapGetters('roles', ['permiteAcao']),

    listaKeys () {
      return {
        id: '',
        Entities: {
          cod: ''
        },
        Devices: {
          serial: '',
        }
      }
    },

     listaItensSelect () {
      let arraySelect = []
      forEach(this.propertiesToArray(this.listaKeys), item => {
        if (item !== 'parentId' ) {
          arraySelect.push({
            text: this.$gettext(item),
            value: item
          })
        }
      })
      return arraySelect
      // return this.$gettext('teste')
    },
  },

  methods: {
    ...mapActions('instalacoes', ['getItens', 'editarItem', 'deletarItem', 'clearItens', 'getItem', 'switchStatus']),

    buscar(val) {
      this.loadingPag = true
      // this.objCampoBusca['_addlFilter'] = { `${typeBusca}_ilike`: `%${val}%` }
      const _addlFilter = {}
      _addlFilter[`${this.typeBusca.replace('.', ':')}_ilike`] = `%${val}%`
      this
        .getItens({ _addlFilter })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },

    propertiesToArray(obj) {
      if (!obj) return

      const isObject = val =>
        typeof val === 'object' && !Array.isArray(val);

      const addDelimiter = (a, b) =>
        a ? `${a}.${b}` : b;

      const paths = (obj = {}, head = '') => {
        return Object.entries(obj)
          .reduce((product, [key, value]) => {
            let fullPath = addDelimiter(head, key)
            return isObject(value) ?
              product.concat(paths(value, fullPath))
            : product.concat(fullPath)
            }, []);
  }

      return paths(obj);
    },

    status (date) {
      return this.isDeleted(date) ? 'Ativado' : 'Desativado'
    },

    acaoBtn (date) {
      return this.situacaoUsuario(date) ? 'Desativar' : 'Ativar'
    },

    getColor (date, inverso = false) {
      return (inverso ? !this.isDeleted(date) : this.isDeleted(date))  ? this.variables.colorSecondary : this.variables.colorError
    },

    isDeleted (date) {
      return moment(date, 'DD/MM/YYYY').isSame(moment('1970-01-01'), 'year') ? !!1 : !!0
    },
  }
}
</script>

<style lang="scss" src="@/assets/styles/view/usuarios-web.scss"></style>

