var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v("Lista de instalações")]),_c('div',{staticClass:"box-table"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listaItens,"search":_vm.search,"width":"300px","flat":"","hide-default-footer":"","loading":_vm.loadingPag,"items-per-page":_vm.itemsPerPage},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BrToolBar',{attrs:{"configFilter":{
                  listaSelect: _vm.listaKeys,
                  getItens: _vm.getItens,
                  jsonData: false,
                  switchStatus: _vm.switchStatus,
                  activeSwitchStatus: true,
                  isStatus: _vm.isStatus,
                  switchStatusName: 'Incluir Desativados'
                },"addlFilter":true,"labelTextField":'Busca por código do device:'},on:{"search":_vm.buscar},scopedSlots:_vm._u([{key:"btnSelect",fn:function(){return [_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.listaItensSelect,"label":"tipo Busca","dense":"","solo":""},model:{value:(_vm.typeBusca),callback:function ($$v) {_vm.typeBusca=$$v},expression:"typeBusca"}})],1)]},proxy:true}])})]},proxy:true},{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.date)+" ")]}},{key:"item.delete",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.delete === '01/01/1970 00:00:00' ? '-' : item.delete)+" ")]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.delete),"dark":""}},[_vm._v(_vm._s(_vm.status(item.delete)))])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-footer"},[_c('div',{staticClass:"qtd-paginacao"},[_vm._v(" "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageStop)+" de "+_vm._s(_vm.totalItens)+" ")]),(_vm.numberOfPages > 1)?_c('v-pagination',{staticClass:"box-table-paginacao",attrs:{"length":_vm.numberOfPages,"circle":"","disabled":_vm.disabled,"total-visible":10},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)]},proxy:true}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }